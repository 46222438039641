<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ formData.Title }}</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!-- <button
          class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
          @click="router.back()"
        >
          <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
          取消
        </button> -->
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="save"
        >
          <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
          儲存
        </button>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-3">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="intro-y box p-5 mt-5">
          <div class="flex items-center border-b border-gray-200">
            <h2 class="font-medium text-base mr-auto">本文</h2>
          </div>

          <div class="grid grid-flow-row gap-2">
            <div class="wysiwyg-box">
              <div class="container-fluid container-xl">
                <div class="row">
                  <div class="col">
                    <CKEditor
                      v-model="formData.Content"
                      :editor="classicEditor"
                      :config="editorConfig"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="intro-y col-span-12 lg:col-span-3">
        <div class="intro-y box mt-5 p-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">狀態</h2>
          </div>

         
        </div>
      </div> -->
    </div>

    <div class="w-full sm:w-auto flex pt-4 sm:mt-0">
      <!-- <button
        class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
        @click="router.back()"
      >
        <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
        取消
      </button> -->
      <button
        class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
        @click="save"
      >
        <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
        儲存
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import "@/assets/css/wysiwyg.css";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import CloudFun, {
  defineComponent,
  ref,
  onMounted,
  Condition,
  Operator,
} from "@cloudfun/core";
import { reactive, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { toRefs } from "vue";

export default defineComponent({
  components: {},
  setup() {
    const model = CloudFun.current?.model;
    const route = useRoute();
    const router = useRouter();
    const title = ref(route.params.title);

    const formData = reactive({
      Title: "",
    });

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList",
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList",
        },
        view: {
          key: "class",
        },
      });
    }

    // const formRules = {
    //   Title: {
    //     required,
    //   },
    // };

    // const validate = useVuelidate(formRules, toRefs(formData));
    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin, schemaCustomization],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    const loadData = (value: string) => {
      const condition = new Condition("Title", Operator.Equal, title.value);
      model?.dispatch("about/query", { condition: condition }).then(
        (payload) => {
          console.log("payload", payload);
          if (payload) Object.assign(formData, payload[0]);
        },
        (failure) => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );
    };

    onMounted(() => {
      loadData(route.params.title as string);
    });

    watch(
      () => route.params.title,
      (current) => {
        title.value = current;
        loadData(current as string);
      }
    );

    return {
      classicEditor,
      router,
      editorConfig,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      formData,
      title,
      loadData,
      // validate,
    };
  },
  methods: {
    save() {
      // this.validate.$touch();
      // if (this.validate.$invalid) return;
      this.$model.dispatch("about/save", { updateRows: [this.formData] }).then(
        (payload) => {
          CloudFun.send("success", {
            subject: `${this.formData.Title} 更新成功！`,
          });
          // this.$router.back();
        },
        (failure) => {
          CloudFun.send("error", { subject: "更新失敗", content: failure });
        }
      );
    },
  },
});
</script>

<style></style>
