<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 h-8">
      <h2 class="text-lg font-medium mr-auto">大事記管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!isEditing && isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onPrint('selected')"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExport('csv', 'selected')"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span> -->
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="onExport('csv', 'selected')"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button
          v-show="!isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增大事記
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form class="xl:flex sm:mr-auto" @submit.prevent="onFilter">
          <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.commitProxy('reload')"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.commitProxy('reload')"
            >
              搜尋
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="onPrint('curret')"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExport('csv')"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExport('html')"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <vxe-grid
          id="grid"
          ref="grid"
          class="mt-5 table-report"
          v-bind="gridOptions"
        >
          <template #operate="{ row }">
            <slot name="operate" :row="row">
              <div class="flex" v-if="!isEditing">
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-1 text-white"
                  title="編輯"
                  @click="edit(row)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-6 text-white"
                  title="取消"
                  @click="remove(row)"
                >
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </slot>
          </template>
          <template #name_filter="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </vxe-grid>
      </div>
    </div>

    <vxe-modal
      ref="modal"
      v-model="form.showEdit"
      :title="form.model == 'edit' ? '編輯大事記' : '新增大事記'"
      width="1000"
      show-zoom="true"
      lock-scroll="false"
      height="700"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
      @show="onResize"
    >
      <template #default>
        <!-- <div class="w-full text-center">
          <file-upload
            name="files"
            ref="uploader"
            extensions="gif,jpg,jpeg,png,webp"
            accept="image/png,image/gif,image/jpeg,image/webp"
            :drop="true"
            v-model="files"
            @input-filter="inputFilter"
            @input-file="inputFile"
          >
          <img
            class="border p-1 rounded"
            style="max-width:500px; max-height:320px;"
            :src="form.formData.Picture.Uri ? form.formData.Picture.Uri : files.length ? files[0].url : 'https://via.placeholder.com/500x320.png?text=Click%20or%20Drop%20file%20here'"
          />
        </file-upload>
          <div v-if="form.formData.Picture.Uri || files.length > 0">
            <button type="button" class="button rounded-full mr-1 mb-2 bg-theme-1 text-white" @click="deletePicture">刪除圖片</button>
          </div>
        </div> -->
        <vxe-form
          :data="form.formData"
          :items="form.formItems"
          :rules="form.formRules"
          title-align="right"
          title-width="100"
          @submit="submitSave"
        >
          <!-- <template #html-editer>
            <div>
              內容
              <CKEditor
                v-model="form.formData.Content"
                :editor="classicEditor"
                :config="simpleEditorConfig"
              />
            </div>
          </template> -->
          <template #file-upload>
            <div class="p-2 border">
              <div>
                <div>附件上傳區</div>
                <file-upload
                  class="mt-2"
                  :multiple="false"
                  v-model="AttachmentFiles"
                  @input-file="inputFile"
                  @change="UploadFiles"
                  ref="AttachmentUploader"
                >
                  <button
                    type="button"
                    class="button rounded-lg bg-theme-1 text-white"
                  >
                    附件上傳
                  </button>
                </file-upload>
              </div>
              <div class="table w-full p-2">
                <table class="w-full border">
                  <thead>
                    <tr class="bg-gray-50 border-b">
                      <th class="p-2 border-r text-sm font-thin">
                        <div class="flex items-center justify-center">名稱</div>
                      </th>
                      <th class="p-2 border-r text-sm font-thin">
                        <div class="flex items-center justify-center">功能</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="text-center border-b text-sm"
                      v-for="(item, index) in form.formData.Files"
                      :key="index"
                    >
                      <td class="p-2 border-r">{{ item.FileName }}</td>
                      <td>
                        <a
                          :href="item.Uri"
                          target="_blank"
                          class="bg-blue-500 p-2 text-white rounded-lg hover:shadow-lg text-sm mr-3"
                          >下載</a
                        >
                        <button
                          type="button"
                          class="bg-red-500 p-2 text-white rounded-lg hover:shadow-lg text-sm"
                          @click="deleteFile(item.Id)"
                        >
                          刪除
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </vxe-form>
      </template>
    </vxe-modal>

    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { onMounted } from "vue";
import CloudFun, {
  Model,
  defineComponent,
  ref,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
} from "@cloudfun/core";
import {
  VxeColumnPropTypes,
  VxeGridInstance,
  VxeGridProps,
  VxeFormPropTypes,
  VxeModalInstance,
} from "vxe-table";
import FileUpload from "vue-upload-component";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import formatDate from "xe-utils/toDateString";

export default defineComponent({
  components: {
    FileUpload,
  },
  setup() {
    const uploader = ref({});
    const files = ref([]);
    const AttachmentUploader = ref({});
    const AttachmentFiles = ref([]);
    const modal = ref({} as VxeModalInstance);
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref("");
    const oriUri = ref("");
    const customFilters = ref<Condition[]>([]);
    const isRowSelected = computed(
      () =>
        (grid.value.getRadioRecord && grid.value.getRadioRecord()) ||
        (grid.value.getCheckboxRecords &&
          grid.value.getCheckboxRecords().length > 0)
    );
    const isEditing = computed(
      () =>
        editingRow.value != null && grid.value.isActiveByRow(editingRow.value)
    );
    var printColumns = [
      { field: "CreatedTimeString" },
      { field: "Category.Name" },
      { field: "Title" },
      { field: "PublishedString" },
      { field: "PublishDateString" },
      { field: "StartDateString" },
      { field: "EndDateString" },
    ];

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [5, 10, 20],
        layouts: [
          "PrevJump",
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "NextJump",
          "Sizes",
          "Total",
        ],
      },

      columns: [
        { type: "checkbox", width: 35, fixed: "left", resizable: false },
        // { field: 'Category.Name', title: '分類名稱' },
        {
          field: "PublishDate",
          title: "活動日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: 120,
          formatter: ({ cellValue }) => formatDate(cellValue, "yyyy/MM/dd"),
        },
        {
          field: "Title",
          title: "活動名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          // filters: [{ checked: false, label: "包含" }],
          // slots: { filter: "name_filter" },
        },
        {
          field: "Source",
          title: "地點",
          width: 200,
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          // filters: [{ checked: false, label: "包含" }],
          // slots: { filter: "name_filter" },
        },
        // {
        //   field: "StartDate",
        //   title: "發佈開始日期",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   formatter: ({ cellValue }) => formatDate(cellValue, "yyyy/MM/dd"),
        // },
        // {
        //   field: "EndDate",
        //   title: "發佈結束日期",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   formatter: ({ cellValue }) => formatDate(cellValue, "yyyy/MM/dd"),
        // },
        {
          field: "Published",
          title: "發布",
          width: 80,
          sortable: true,
          formatter: ({ cellValue }) => {
            return cellValue ? "是" : "否";
          },
        },
        // {
        //   field: "Ordinal",
        //   title: "排序",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        // },
        {
          title: "操作",
          width: 100,
          fixed: "right",
          align: "center",
          resizable: false,
          slots: { default: "operate" },
        },
      ],
      proxyConfig: {
        message: false,
        sort: true,
        filter: true,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params) => {
            //params.sorts.push({ column: 'Ordinal', sortBy: 'asc',property:'Ordinal', order: 'asc' });
            //console.log(params)
            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: keyword.value,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(customFilters.value), // custom filters
            };
            if (queryParams.sortings?.length === 0) {
              queryParams.sortings?.push(new Sorting("Ordinal", 0));
              queryParams.sortings?.push(new Sorting("CreatedTime", 1));
            }
            //console.log(queryParams)
            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) =>
              model?.dispatch("news/load", queryParams).then(
                (payload) => {
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "讀取失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) =>
              model?.dispatch("news/save", deleteParams).then(
                (payload) => resolve(payload),
                (reason) => {
                  CloudFun.send("error", {
                    subject: "刪除失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          save: (params) => {
            const saveParams = {
              insertRows: params.body.insertRecords,
              updateRows: params.body.updateRecords,
              deleteRows: params.body.removeRecords,
            };
            return new Promise((resolve, reject) =>
              model?.dispatch("news/save", saveParams).then(
                (payload) => {
                  editingRow.value = null;
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "保存失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
        },
      },
    } as VxeGridProps);

    //modal 執行 新增or修改
    const submitSave = () => {
      var obj = files.value;
      const formData = new FormData();
      if (obj.length > 0) {
        formData.append("file", obj[0]["file"]);
      }
      formData.append("json", JSON.stringify(form.formData));
      var method = form.model == "insert" ? "insert" : "update";
      gridOptions.loading = true;
      //console.log(form.model, method)
      model
        ?.dispatch(`news/${method}`, formData)
        .then(
          (paylod) => {
            //var sorting = new Sorting('CreatedTime', 1);
            grid.value.commitProxy("query").finally(() => {});
            CloudFun.send("info", {
              subject: method == "insert" ? "新增成功" : "更新成功",
              content: method == "insert" ? "大事記新增完成" : "大事記更新完成",
            });
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally(() => {
          form.showEdit = false;
          gridOptions.loading = false;
        });
    };

    const form = reactive({
      model: "insert",
      CityList: [],
      DistrictsList: [],
      CategoryList: [],
      selectRow: true,
      showEdit: false,
      formData: {
        Id: 0,
        CreatedTimeString: "",
        CreatedTime: "",
        Name: "",
        Published: true,
        Ordinal: 0,
        ClickCount: 0,
        Remark: "",
        Source: "",
        Top: false,
        Title: "",
        PublishDate: "",
        StartDate: "",
        EndDate: "",
        Operator: "",
        OperatorId: "",
        Content: "",
        Picture: {
          Id: 0,
          Uri: "",
        },
        Files: [],
      },
      formRules: {
        Title: [{ required: true, message: "請輸入標題" }],
        Source: [{ required: true, message: "請輸入地點" }],
        // CategoryId: [{ required: true, message: "請輸入分類名稱" }],
        Published: [{ required: true, message: "請輸入發佈" }],
        PublishDate: [{ required: true, message: "請輸入發佈日期" }],
        // Content: [{ required: true, message: "請輸入內容" }],
      } as VxeFormPropTypes.Rules,
      formItems: [
        {
          field: "PublishDate",
          title: "活動日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "date", placeholder: "發佈日期" },
          },
        },
        {
          field: "Published",
          title: "是否發佈",
          span: 12,
          itemRender: {
            name: "$radio",
            options: [
              { label: "否", value: false },
              { label: "是", value: true },
            ],
          },
        },
        {
          field: "Source",
          title: "地點",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "地點" },
          },
        },
        {},
        {
          field: "Title",
          title: "活動名稱",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "事記主題" } },
        },
        // { span: 24, slots: { default: "file-upload" } },
        // { field: 'CategoryId', title: '分類', span: 12, itemRender: { name: '$select', options:[] } },

        // {
        //   field: "Top",
        //   title: "熱門",
        //   span: 12,
        //   itemRender: {
        //     name: "$radio",
        //     options: [
        //       { label: "否", value: false },
        //       { label: "是", value: true },
        //     ],
        //   },
        // },
        // {
        //   field: "Ordinal",
        //   title: "排序",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "number", placeholder: "排序" },
        //   },
        // },
        // {
        //   field: "Source",
        //   title: "資料來源",
        //   span: 12,
        //   itemRender: { name: "$input", props: { placeholder: "資料來源" } },
        // },

        // {
        //   field: "StartDate",
        //   title: "發佈開始日期",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "date", placeholder: "發佈開始日期" },
        //   },
        // },
        // {
        //   field: "EndDate",
        //   title: "發佈結束日期",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "date", placeholder: "發佈結束日期" },
        //   },
        // },
        // {
        //   field: "ClickCount",
        //   title: "點擊次數",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "number", placeholder: "點擊次數", readonly: true },
        //   },
        // },
        //{ field: 'Content', title: '內容', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '內容' } } },
        // { span: 24, slots: { default: "html-editer" } },
        { span: 24, slots: { default: "file-upload" }, visible: false },
        {
          align: "right",
          span: 24,
          titleAlign: "left",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確認", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        }, //, events: { click: save }
      ] as VxeFormPropTypes.Items,
    });

    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;
    // const getCategories = () => {
    //   return new Promise((resolve, reject) => {
    //     Model.createHttpClient(baseUrl)
    //       .get(`${baseUrl}/api/NewsCategory`, {
    //         params: {
    //           keyword: null,
    //           orderColumn: "CreatedTime",
    //           descending: true,
    //           skipRows: null,
    //           takeRows: null,
    //           simple: true,
    //         },
    //       })
    //       .then(function (response: any) {
    //         resolve(response.payload);
    //       })
    //       .catch(function (error) {
    //         reject(error);
    //         console.log(error.response);
    //       });
    //   });
    // };

    onMounted(() => {
      // getCategories().then((data: any) =>{
      //     var item = form.formItems[1];
      //     if (item.itemRender && data.length > 0) {
      //       form.CategoryList = data;
      //       item.itemRender.options = data;
      //       //console.log(item.itemRender.options, form.CategoryList[0]['value'])
      //     }
      // });
    });

    const onResize = () => {
      //if(form.formData.Picture && form.formData.Picture.Uri) {
      // setTimeout(() => {
      //   console.log('test')
      //   var ins = (document.getElementsByClassName('vxe-modal--box')[0])
      //   var top = (window.screen.availHeight - ins.clientHeight) / 2;
      //   console.log(window.screen.availHeight, ins.clientHeight)
      //   console.log(top)
      //   modal.value.setPosition(top)
      // }, 200);
      //}
    };

    // const classicEditor = ClassicEditor;
    // const simpleEditorConfig = {
    //   extraPlugins: [UploadAdapterPlugin],
    //   toolbar: {
    //     shouldNotGroupWhenFull: true,
    //     items: [
    //       "heading",
    //       "|",
    //       "fontFamily",
    //       "fontSize",
    //       "fontColor",
    //       "fontBackgroundColor",
    //       "|",
    //       "bold",
    //       "italic",
    //       "underline",
    //       "bulletedList",
    //       "numberedList",
    //       "outdent",
    //       "indent",
    //       "highlight",
    //       "insertTable",
    //       "|",
    //       "link",
    //       "blockQuote",
    //       "imageInsert",
    //       "mediaEmbed",
    //       // 'codeBlock',
    //       // 'htmlEmbed',
    //       "|",
    //       "|",
    //       "undo",
    //       "redo",
    //     ],
    //   },
    //   removePlugins: ["Markdown"],
    //   image: {
    //     toolbar: [
    //       "imageTextAlternative",
    //       "imageStyle:full",
    //       "imageStyle:side",
    //       "linkImage",
    //     ],
    //   },
    //   table: {
    //     contentToolbar: [
    //       "tableColumn",
    //       "tableRow",
    //       "mergeTableCells",
    //       "tableCellProperties",
    //       "tableProperties",
    //     ],
    //   },
    //   heading: {
    //     options: [
    //       {
    //         model: "paragraph",
    //         title: "Paragraph",
    //         class: "ck-heading_paragraph",
    //       },
    //       {
    //         model: "heading1",
    //         view: { name: "h1", classes: "font-bold text-xl" },
    //         title: "Heading 1",
    //         class: "ck-heading_heading1",
    //       },
    //       {
    //         model: "heading2",
    //         view: { name: "h2", classes: "font-bold text-base" },
    //         title: "Heading 2",
    //         class: "ck-heading_heading2",
    //       },
    //     ],
    //   },
    // };

    const UploadFiles = () => {
      var obj = AttachmentFiles.value;
      const formData = new FormData();
      if (obj.length > 0) {
        var file = obj[0]["file"];
        var limit = 1024 * 1024 * 25;
        if (file["size"] >= limit) {
          CloudFun.send("error", {
            subject: "附件上傳失敗！",
            content: "檔案大小不得超過25M",
          });
          return;
        }
        console.log(file["name"]);
        if (
          !/\.(gif|jpg|jpeg|png|webp|zip|rar|doc|docx|ppt|pptx|xlsx|xls|csv|txt|pdf)$/i.test(
            file["name"]
          )
        ) {
          CloudFun.send("error", {
            subject: "附件上傳失敗！",
            content: "不支援此檔案類型",
          });
          return;
        }
        formData.append("files", file);
      }

      model
        ?.dispatch(`files/insert`, {
          formData: formData,
          id: form.formData.Id.toString(),
          mode: "other",
          name: "news",
        })
        .then(
          () => {
            CloudFun.send("info", {
              subject: "新增成功",
              content: "新增附件完成",
            });
            ReloadFiles();
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally(() => {});
    };

    const ReloadFiles = () => {
      model?.dispatch("news/find", form.formData.Id).then(
        (paylod) => {
          AttachmentFiles.value = [];
          if (paylod.Files && paylod.Files.length > 0) {
            form.formData.Files = paylod.Files;
          } else {
            form.formData.Files = [];
          }
        },
        (failure) =>
          CloudFun.send("error", {
            subject: "附件讀取失敗",
            content: failure.message,
          })
      );
    };

    return {
      UploadFiles,
      // classicEditor,
      // simpleEditorConfig,
      form,
      grid,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      submitSave,
      // getCategories,
      files,
      modal,
      uploader,
      onResize,
      oriUri,
      AttachmentUploader,
      AttachmentFiles,
      ReloadFiles,
    };
  },
  methods: {
    reset() {
      this.form.formData.Id = 0;
      this.form.formData.CreatedTimeString = "";
      this.form.formData.CreatedTime = "";
      this.form.formData.Title = "";
      this.form.formData.Published = false;
      this.form.formData.Source = "";
      this.form.formData.Top = false;
      this.form.formData.Ordinal = 0;
      this.form.formData.ClickCount = 0;
      this.form.formData.Remark = "";
      // this.form.formData.Category = ''
      // this.form.formData.CategoryId = this.form.CategoryList.length > 0 ? this.form.CategoryList[0]['value'] : 0;
      this.form.formData.Title = "";
      this.form.formData.PublishDate = "";
      this.form.formData.StartDate = "";
      this.form.formData.EndDate = "";
      this.form.formData.Operator = "";
      this.form.formData.OperatorId = "";
      this.form.formData.Content = "";
      this.form.formData.Files = [];
      if (this.files.length > 0) this.files = [];
      if (this.AttachmentFiles.length > 0) this.AttachmentFiles = [];
      this.form.formData.Picture = { Uri: "", Id: 0 };
    },
    edit(row: any) {
      if (this.files.length > 0) this.files = [];
      if (this.AttachmentFiles.length > 0) this.AttachmentFiles = [];
      this.$model.dispatch("news/find", row.Id).then(
        (paylod) => {
          Object.assign(this.form.formData, paylod);
          // if(this.form.formData.Picture.Uri) {
          //   setTimeout(() => {
          //     this.oriUri = this.form.formData.Picture.Uri;
          //     this.form.formData.Picture.Uri = this.form.formData.Picture.Uri + "?" + new Date().getTime();
          //   }, 200);
          // } else { this.oriUri = '' }
          this.form.model = "edit";
          this.form.showEdit = true;
          var item = this.form.formItems[5];
          if (item) item.visible = true;
          //console.log(paylod)
        },
        (failure) =>
          this.$send("error", {
            subject: "操作失敗！",
            content: failure.message,
          })
      );
    },
    addNewRow() {
      this.reset();
      this.form.model = "insert";
      this.form.showEdit = true;
      var item = this.form.formItems[5];
      if (item) item.visible = false;
    },
    save(row?: any) {
      const {
        insertRecords,
        updateRecords,
        removeRecords,
      } = this.grid.getRecordset();
      let needSave =
        insertRecords.length || updateRecords.length || removeRecords.length;
      needSave &&=
        row &&
        (insertRecords.some((e) => e._XID === row._XID) ||
          updateRecords.some((e) => e._XID === row._XID) ||
          removeRecords.some((e) => e._XID === row._XID));
      const isRowEditing = row && this.editingRow === row;
      if (needSave) {
        this.grid.commitProxy("save").then(() => {
          if (isRowEditing && this.editingRow === row) this.edit(row);
          else this.editingRow = null;
        });
      } else if (!row || row === this.editingRow) this.editingRow = null;
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch("news/delete", row.Id).then(
          () => {
            this.grid.commitProxy("query").finally(() => {
              this.gridOptions.loading = false;
            });
          },
          (failure) =>
            this.$send("error", {
              subject: "活動快遞刪除失敗",
              content: failure.message,
            })
        );
      }
    },
    removeSelectedRows() {
      cash("#batch-dropdown").dropdown("hide");
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy("delete");
      }
    },
    changeFilterEvent(
      event: Event,
      option: VxeColumnPropTypes.Filter,
      panel: any,
      toogleChecked?: boolean
    ) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value)
          option.value = new Condition("Name", Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked)
        panel.changeOption(event, !!option.data, option);
    },
    async onExport(
      type: "csv" | "html",
      mode: "current" | "selected" | "all" = "all"
    ) {
      const config: any = { ...this.gridOptions.exportConfig, type, mode };
      if (mode === "all")
        config.data = await this.$model.dispatch("news/query");
      console.log(config);
      this.grid.exportData({ ...config, type, mode });
    },
    async onPrint(mode: "current" | "selected" | "all" = "all") {
      const config: any = { ...this.gridOptions.printConfig, mode };
      if (mode === "all")
        config.data = await this.$model.dispatch("news/query");
      this.grid.print(config);
    },
    inputFile(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {});
      }
      if (!newFile && oldFile) {
      }
    },
    inputFilter(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          //this.alert('Your choice is not a picture')
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
          this.form.formData.Picture.Uri = newFile.url;
          //setTimeout(() => {
          // var ins = (document.getElementsByClassName('vxe-modal--box')[0])
          // var top = (window.screen.availHeight - ins.clientHeight) / 2;
          // console.log(top)
          // this.modal.setPosition(top)
          //}, 200);
        }
      }
    },
    deletePicture() {
      if (this.form.formData.Picture.Id == 0) {
        //CloudFun.send('error', { subject: '操作失敗！', content: '請確認圖片是否存在！' })
        if (this.files.length > 0) this.removeFile();
        return;
      }
      this.$model
        .dispatch(`picture/deletePicture`, this.form.formData.Picture.Id)
        .then(
          (paylod) => {
            CloudFun.send("info", {
              subject: "刪除成功！",
              content: "圖片刪除成功",
            });
            this.removeFile();
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally(() => {});
    },
    removeFile() {
      console.log(this.files.length > 0);
      if (this.files.length > 0) this.files = [];
      this.form.formData.Picture.Uri = "";
    },
    deleteFile(id: any) {
      this.$model
        .dispatch(`files/delete`, id)
        .then(
          () => {
            CloudFun.send("info", {
              subject: "刪除成功！",
              content: "附件刪除成功",
            });
            this.ReloadFiles();
          },
          (failure) =>
            CloudFun.send("error", {
              subject: "附件刪除失敗！",
              content: failure,
            })
        )
        .finally(() => {});
    },
    downloadFile(id: any) {
      this.$model
        .dispatch(`files/download`, id)
        .then(
          (data) => {
            if (data) window.open(data, "_blank");
          },
          (failure) =>
            CloudFun.send("error", {
              subject: "附件下載失敗！",
              content: failure,
            })
        )
        .finally(() => {});
    },
  },
});
</script>
