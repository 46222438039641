import { Model } from "@cloudfun/core";
import { main, Store as MainStore, State as MainState } from "./main";
import configuration from "./configuration";
import role from "./role";
import user from "./user";
import actionLog from "./action-log";
import category from "./category";
import bannerPosition from "./banner-position";
import banner from "./banner";
import pictureAlbum from "./picture-album";
import picture from "./picture";
import attribute from "./attribute";
import attributeValue from "./attribute-value";
import newsCategory from "./news-category";
import news from "./news";
import linkCategory from "./link-category";
import link from "./link";
import member from "./member";
import about from "./about";
import forum from "./forum";
import forumReply from "./forumReply";
import forumCategory from "./forumCategory";
import files from "./files";
import industry from "./industry";
import industryCategory from "./industryCategory";
import fileCategory from "./fileCategory";
import classification from "./classification";
import certification from "./certification";
import job from "./job";
import filesDocument from "./filesDocument";

const model = new Model("EmptyNext", {
  main,
  configuration,
  role,
  user,
  actionLog,
  category,
  bannerPosition,
  banner,
  pictureAlbum,
  picture,
  attribute,
  attributeValue,
  newsCategory,
  news,
  linkCategory,
  link,
  member,
  about,
  forum,
  forumReply,
  forumCategory,
  files,
  industry,
  industryCategory,
  fileCategory,
  classification,
  certification,
  job,
  filesDocument
});

const backendServer = process.env.VUE_APP_BACKEND_URL;
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.onLogin = data =>
  model.clients.unauthorized.post("System/Login", {
    account: data.account,
    password: data.password,
    captchaToken: data.captchaToken,
    captcha: data.captcha
  });
model.onLogout = () => model.clients.authorized.post("System/Logout");
model.onReloadUser = () => model.clients.authorized.post("System/CurrentUser");
model.onReloadEnums = () =>
  model.clients.unauthorized.get("System/Enumerations");
model.onLog = message => model.clients.unauthorized.post("System/Log", message);

export type State = { main: MainState };
export type Store = MainStore<Pick<State, "main">>;

export default model;
