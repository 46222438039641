<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ formData.Category.Name }}
      </h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
          @click="router.back()"
        >
          <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
          取消
        </button>
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="save"
        >
          <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
          儲存
        </button>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-3">
      <div class="intro-y col-span-12 lg:col-span-9">
        <div class="intro-y box p-5 mt-5">
          <div class="grid grid-cols-1">
            <div>
              <label>主題名稱</label>
              <vxe-input
                placeholder="主題名稱"
                class="w-full"
                v-model.trim="validate.Title.$model"
                :class="{ 'bg-red-200': validate.Title.$error }"
              ></vxe-input>
              <template v-if="validate.Title.$error">
                <div
                  v-for="(error, index) in validate.Title.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
          </div>
          <div class="grid grid-flow-row gap-1 pt-5"></div>
        </div>
        <div class="intro-y box p-5 mt-5">
          <div class="flex items-center border-b border-gray-200">
            <h2 class="font-medium text-base mr-auto">本文</h2>
          </div>

          <div class="grid grid-flow-row gap-2">
            <div class="wysiwyg-box">
              <div class="container-fluid container-xl">
                <div class="row">
                  <div class="col">
                    <CKEditor
                      v-model="formData.Content"
                      :editor="classicEditor"
                      :config="editorConfig"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-3">
        <div class="intro-y box mt-5 p-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">設定</h2>
          </div>
          <div class="flex flex-col p-1">
            <label>發佈開始日期</label>
            <vxe-input
              v-model="formData.StartDate"
              type="date"
              clearable
              placeholder=""
            ></vxe-input>
          </div>
          <div class="flex flex-col p-1">
            <label>發佈結束日期</label>
            <vxe-input
              v-model="formData.EndDate"
              type="date"
              clearable
              placeholder=""
            ></vxe-input>
          </div>

          <div class="flex flex-col p-1">
            <label>發佈狀態</label>
            <vxe-switch
              v-model="formData.Published"
              open-label="上架"
              close-label="下架"
            ></vxe-switch>
          </div>

          <div class="flex flex-col p-1">
            <label>排序值</label>
            <vxe-input
              v-model="formData.Ordinal"
              type="integer"
              min="0"
            ></vxe-input>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full sm:w-auto flex pt-4 sm:mt-0">
      <button
        class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
        @click="router.back()"
      >
        <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
        取消
      </button>
      <button
        class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
        @click="save"
      >
        <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
        儲存
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import "@/assets/css/wysiwyg.css";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import CloudFun, { defineComponent, ref, onMounted } from "@cloudfun/core";
import { reactive } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { toRefs } from "vue";
import { News } from "@/models/data";

export default defineComponent({
  components: {},
  setup() {
    const model = CloudFun.current?.model;
    const route = useRoute();
    const router = useRouter();
    const title = ref("");
    const id = ref(route.query.id);
    const formData = reactive({ Title: "" } as News);

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList",
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList",
        },
        view: {
          key: "class",
        },
      });
    }

    const formRules = {
      Title: {
        required,
      },
    };

    const validate = useVuelidate(formRules, toRefs(formData));
    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin, schemaCustomization],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    const initData = () => {
      formData.Id = 0;
      formData.Title = "";
      formData.Ordinal = 0;
      formData.CategoryId = route.query.category as string;
      formData.Category = {
        Id: parseInt(route.query.category as string),
        Name: route.query.categoryName as string,
      };
      formData.Top = false;
      formData.Published = true;
      formData.Content = "";
      formData.IsNew = false;
    };

    const loadData = (value: string) => {
      // eslint-disable-next-line no-unused-expressions
      model?.dispatch("news/find", value).then(
        (payload) => {
          Object.assign(formData, payload);
        },
        (failure) => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );
    };

    onMounted(() => {
      if (route.query.id) loadData(route.query.id as string);
      else initData();
    });

    return {
      classicEditor,
      router,
      editorConfig,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      formData,
      title,
      loadData,
      validate,
      id,
      initData,
    };
  },
  methods: {
    save() {
      this.validate.$touch();
      console.log(this.validate);
      if (this.validate.$invalid) return;
      const params = { insertRows: Array<News>(), updateRows: Array<News>() };

      if (this.formData.Id == null || this.formData.Id === 0)
        params.insertRows.push(this.formData);
      else params.updateRows.push(this.formData);
      this.$model.dispatch("news/save", params).then(
        (payload) => {
          CloudFun.send("success", {
            subject: `${this.formData.Title} 更新成功！`,
          });
          this.$router.back();
        },
        (failure) => {
          CloudFun.send("error", { subject: "更新失敗", content: failure });
        }
      );
    },
  },
});
</script>

<style></style>
