<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">產業資訊</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-show="!isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
            <select
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model="selectdType"
              @change="grid.refresh"
            >
              <option value="">請選擇類別</option>
              <option value="0">產業新聞</option>
              <option value="1">活動成果</option>
              <option value="2">活動快報</option>
              <option value="3">政策法規</option>
            </select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
             <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              initData(row);
              callback();
            }
          "
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              :title-width="60"
              title-align="right"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #html-editer>
                <div>
                  內容
                  <CKEditor
                    v-model="row.Content"
                    :editor="classicEditor"
                    :config="editorConfig"
                  />
                </div>
              </template>
              <template #file-upload>
                <div class="p-2 border">
                  <div>
                    <div>附件上傳區</div>
                    <file-upload
                      class="mt-2"
                      :multiple="false"
                      v-model="AttachmentFiles"
                      @input-file="inputFile"
                      @change="UploadFiles(row)"
                      ref="AttachmentUploader"
                    >
                      <button
                        type="button"
                        class="button rounded-lg bg-theme-1 text-white"
                      >
                        附件上傳
                      </button>
                    </file-upload>
                  </div>
                  <div class="table w-full p-2">
                    <table class="w-full border">
                      <thead>
                        <tr class="bg-gray-50 border-b">
                          <th class="p-2 border-r text-sm font-thin">
                            <div class="flex items-center justify-center">
                              名稱
                            </div>
                          </th>
                          <th class="p-2 border-r text-sm font-thin">
                            <div class="flex items-center justify-center">
                              功能
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="text-center border-b text-sm"
                          v-for="(item, index) in row.Files"
                          :key="index"
                        >
                          <td class="p-2 border-r">{{ item.FileName }}</td>
                          <td>
                            <a
                              :href="item.Uri"
                              target="_blank"
                              class="bg-blue-500 p-2 text-white rounded-lg hover:shadow-lg text-sm mr-3"
                              >下載</a
                            >
                            <button
                              type="button"
                              class="bg-red-500 p-2 text-white rounded-lg hover:shadow-lg text-sm"
                              @click="deleteFile(item.Id, row)"
                            >
                              刪除
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </vxe-form>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>

<script>
/* eslint-disable */
import { onMounted } from "vue";
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import FileUpload from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    FileUpload,
  },
  setup() {
    const model = CloudFun.current?.model;

    const grid = ref({});
    const AttachmentUploader = ref({});
    const AttachmentFiles = ref([]);
    const selectdType = ref("");
    var printColumns = [
      { field: "Title" },
      { field: "Ordinal" },
      { field: "PublishedString" },
    ];

    const gridOptions = {
      title: "產業資訊",
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      sortConfig: {
        defaultSort: [
          { field: "Ordinal", orderby: "asc" },
          { field: "CreatedTime", order: "desc" },
        ],
      },
      printConfig: {
        sheetName: "產業資訊",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "產業資訊",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Type",
          title: "類別",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: 120,
          formatter: ({ cellValue }) => {
            switch (cellValue) {
              case 0:
                return "產業新聞";
              case 1:
                return "活動成果";
              case 2:
                return "活動快報";
              case 3:
                return "政策法規";
            }
          },
        },
        {
          field: "Title",
          title: "標題",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,

          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Ordinal",
          title: "排序",
          width: 80,
          sortable: true,
        },
        {
          field: "Published",
          title: "發布",
          width: 80,
          sortable: true,
          formatter: ({ cellValue }) => {
            return cellValue ? "是" : "否";
          },
        },
      ],
      promises: {
        query: model
          ? (params) => {
              params.type = selectdType.value;
              if (params.sortings.length === 0)
                params.sortings.push(
                  { column: "Ordinal", orderby: 0 },
                  { column: "CreatedTime", order: 1 }
                );
              return model.dispatch("industry/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("industry/query") : undefined,
        save: model
          ? (params) => model.dispatch("industry/save", params)
          : undefined,
      },
      modalConfig: { width: "80%", height: "80%" },
    };
    const formOptions = {
      items: [
        {
          field: "Type",
          title: "分類",
          span: 12,
          itemRender: {
            name: "$select",
            options: [
              { label: "產業新聞", value: 0 },
              { label: "活動成果", value: 1 },
              { label: "活動快報", value: 2 },
              { label: "政策法規", value: 3 },
            ],
          },
        },
        {
          field: "Title",
          title: "標題",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          field: "Source",
          title: "來源",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              type: "number",
              placeholder: "請輸入排序",
              clearable: true,
            },
          },
        },
        {
          field: "Published",
          title: "發佈",
          span: 12,
          itemRender: {
            name: "$radio",
            options: [
              { label: "是", value: true },
              { label: "否", value: false },
            ],
          },
        },
        {
          field: "Top",
          title: "熱門",
          span: 12,
          itemRender: {
            name: "$radio",
            options: [
              { label: "是", value: true },
              { label: "否", value: false },
            ],
          },
        },
        {
          field: "PublishDate",
          title: "發佈日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "date", placeholder: "發佈日期" },
          },
        },
        { span: 24, slots: { default: "html-editer" } },
        { span: 24, slots: { default: "file-upload" }, visible: false },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Title: [
          { type: "string", message: "請輸入標題", max: 128, required: true },
        ],
        Ordinal: [{ type: "number", required: true }],
        Published: [{ required: true }],
        Source: [{ required: true }],
        PublishDate: [{ required: true }],
        //CategoryId: [{ required: true }],
      },
    };

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          // 'htmlEmbed',
          "|",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    const initData = (row) => {
      row.Published = false;
      row.Ordinal = 0;
      row.Type = 0;
      row.Top = false;
      row.Files = [];
      var item = formOptions.items[8];
      if (item) item.visible = false;
    };

    const UploadFiles = (data) => {
      var obj = AttachmentFiles.value;
      const formData = new FormData();
      if (obj.length > 0) {
        var file = obj[0]["file"];
        var limit = 1024 * 1024 * 25;
        if (file["size"] >= limit) {
          CloudFun.send("error", {
            subject: "附件上傳失敗！",
            content: "檔案大小不得超過25M",
          });
          return;
        }
        console.log(file["name"]);
        if (
          !/\.(gif|jpg|jpeg|png|webp|zip|rar|doc|docx|ppt|pptx|xlsx|xls|csv|txt|pdf)$/i.test(
            file["name"]
          )
        ) {
          CloudFun.send("error", {
            subject: "附件上傳失敗！",
            content: "不支援此檔案類型",
          });
          return;
        }
        formData.append("files", file);
      }

      model
        ?.dispatch(`files/insert`, {
          formData: formData,
          id: data.Id.toString(),
          mode: "other",
          name: "industry",
        })
        .then(
          () => {
            CloudFun.send("info", {
              subject: "新增成功",
              content: "新增附件完成",
            });
            ReloadFiles(data);
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally(() => {});
    };

    const ReloadFiles = (data) => {
      model?.dispatch("industry/find", data.Id).then(
        (paylod) => {
          AttachmentFiles.value = [];
          if (paylod.Files && paylod.Files.length > 0) {
            data.Files = paylod.Files;
          } else {
            data.Files = [];
          }
        },
        (failure) =>
          CloudFun.send("error", {
            subject: "附件讀取失敗",
            content: failure.message,
          })
      );
    };

    // const getCategoty = () => {
    //     return new Promise((resolve, reject) => {
    //       model.dispatch('industryCategory/query', { keyword: null, skipRows: null, takeRows: null, simple: true, type: 1 }).then(
    //         response => resolve(response),
    //         reason =>  reject(reason)
    //       );
    //   });
    // };

    onMounted(() => {
      // getCategoty().then((data) =>{
      //   var item = formOptions.items[0];
      //   if (item.itemRender && data.length > 0) {
      //     item.itemRender.options = data;
      //   }
      // });
    });

    return {
      classicEditor,
      editorConfig,
      grid,
      selectdType,
      gridOptions,
      formOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      AttachmentUploader,
      AttachmentFiles,
      ReloadFiles,
      UploadFiles,
      initData,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id
        ? await this.$model.dispatch("industry/find", row.Id)
        : undefined;
      if (entity) {
        Object.assign(row, entity);
        var item = this.formOptions.items[8];
        item.visible = true;
        // if (item && row.Type == 0){
        //   item.visible = true;
        // }else{
        //   item.visible = false;
        // }
      }
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {});
      }
      if (!newFile && oldFile) {
      }
    },
    deleteFile(id, row) {
      this.$model
        .dispatch(`files/delete`, id)
        .then(
          () => {
            CloudFun.send("info", {
              subject: "刪除成功！",
              content: "附件刪除成功",
            });
            this.ReloadFiles(row);
          },
          (failure) =>
            CloudFun.send("error", {
              subject: "附件刪除失敗！",
              content: failure,
            })
        )
        .finally(() => {});
    },
  },
});
</script>
