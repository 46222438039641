<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">關於我們管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!-- <button
          v-show="!isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              initData(row);
              callback();
            }
          "
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>

<script type="ts">
/* eslint-disable */
import { onMounted } from "vue";
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import FileUpload from "vue-upload-component";
import "@/assets/css/wysiwyg.css";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: {
    Grid,
    FileUpload,
  },
  setup() {
    const model = CloudFun.current?.model;
    const router = useRouter();
    const grid = ref({});
    const gridOptions = {
      title: "關於我們",
      multiselect: false,
      canCreateRow: false,
      canDeleteRow: false,
      canEditRow: true,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      sortConfig: {
        defaultSort: [
          { field: "Ordinal", orderby: "asc" },
          { field: "CreatedTime", order: "desc" },
        ],
      },
      columns: [
        {
          field: "Title",
          title: "標題",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          field: "Ordinal",
          title: "排序",
          width: 80,
          sortable: true,
        },
        {
          field: "Published",
          title: "發布",
          width: 80,
          sortable: true,
          formatter: ({ cellValue }) => {
            return cellValue ? "是" : "否";
          },
        },
      ],
      promises: {
        query: model
          ? (params) => {
              if (params.sortings.length === 0)
                params.sortings.push(
                  { column: "Ordinal", orderby: 0 },
                  { column: "CreatedTime", order: 1 }
                );
              return model.dispatch("about/query", params);
            }
          : undefined,
        queryAll: model ? () => model.dispatch("about/query") : undefined,
        save: model
          ? (params) => model.dispatch("about/save", params)
          : undefined,
      },
      modalConfig: { height: 600 },
    };

    onMounted(() => {
      grid.value.type = 0;
    });

    return {
      grid,
      gridOptions,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row, callback) {
      this.$router.push({
        path: "/about/detail",
        query: { id: row.Id },
      });
    },
  },
});
</script>
