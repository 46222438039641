
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import "@/assets/css/wysiwyg.css";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import CloudFun, {
  defineComponent,
  ref,
  onMounted,
  Condition,
  Operator,
} from "@cloudfun/core";
import { reactive, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { toRefs } from "vue";

export default defineComponent({
  components: {},
  setup() {
    const model = CloudFun.current?.model;
    const route = useRoute();
    const router = useRouter();
    const title = ref(route.params.title);

    const formData = reactive({
      Title: "",
    });

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList",
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList",
        },
        view: {
          key: "class",
        },
      });
    }

    // const formRules = {
    //   Title: {
    //     required,
    //   },
    // };

    // const validate = useVuelidate(formRules, toRefs(formData));
    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin, schemaCustomization],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    const loadData = (value: string) => {
      const condition = new Condition("Title", Operator.Equal, title.value);
      model?.dispatch("about/query", { condition: condition }).then(
        (payload) => {
          console.log("payload", payload);
          if (payload) Object.assign(formData, payload[0]);
        },
        (failure) => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );
    };

    onMounted(() => {
      loadData(route.params.title as string);
    });

    watch(
      () => route.params.title,
      (current) => {
        title.value = current;
        loadData(current as string);
      }
    );

    return {
      classicEditor,
      router,
      editorConfig,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      formData,
      title,
      loadData,
      // validate,
    };
  },
  methods: {
    save() {
      // this.validate.$touch();
      // if (this.validate.$invalid) return;
      this.$model.dispatch("about/save", { updateRows: [this.formData] }).then(
        (payload) => {
          CloudFun.send("success", {
            subject: `${this.formData.Title} 更新成功！`,
          });
          // this.$router.back();
        },
        (failure) => {
          CloudFun.send("error", { subject: "更新失敗", content: failure });
        }
      );
    },
  },
});
