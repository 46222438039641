
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import "@/assets/css/wysiwyg.css";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import CloudFun, {
  defineComponent,
  ref,
  onMounted,
  Condition,
  Operator,
} from "@cloudfun/core";
import { reactive, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { toRefs } from "vue";

export default defineComponent({
  components: {},
  setup() {
    const model = CloudFun.current?.model;
    const route = useRoute();
    const router = useRouter();
    const title = ref("");
    const code = ref(route.query.code as string);
    const lang = ref(route.query.lang as string);
    const zhCKEditor = ref({});
    const enCKEditor = ref({});

    const form = reactive({
      formData: {
        Title: "",
        EnglishTitle: "",
        Content: "",
        EnglishContent: "",
        Code: "",
      },
    });

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList",
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList",
        },
        view: {
          key: "class",
        },
      });
    }

    const formRules = {
      Title: {
        required,
      },
      EnglishTitle: {
        required,
      },
    };

    const validate = useVuelidate(formRules, toRefs(form.formData));
    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin, schemaCustomization],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    const loadData = (value: string) => {
      const params = {
        condition: new Condition("Code", Operator.Equal, value),
      };

      form.formData.Content = "";
      form.formData.EnglishContent = "";
      // eslint-disable-next-line no-unused-expressions
      model?.dispatch("about/query", params).then(
        (payload) => {
          console.log("payload", payload);
          if (payload) {
            const formData = payload[0];
            console.log(
              "🚀 ~ file: Detail.vue ~ line 382 ~ loadData ~ formData",
              formData
            );

            // form.formData.Title = formData.Title;
            // form.formData.EnglishTitle = formData.EnglishTitle;

            // form.formData.Content =
            //   formData.Content && formData.Content.length > 0
            //     ? formData.Content
            //     : "";
            // form.formData.EnglishContent =
            //   formData.EnglishContent && formData.EnglishContent.length > 0
            //     ? formData.EnglishContent
            //     : "";
            Object.assign(form.formData, formData);
          }
        },
        (failure) => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );
    };

    onMounted(() => {
      loadData(route.query.code as string);
    });

    watch(
      () => route.query.code,
      (current) => {
        if (current) loadData(current as string);
      }
    );

    return {
      classicEditor,
      router,
      editorConfig,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      form,
      title,
      loadData,
      validate,
      code,
      lang,
      zhCKEditor,
      enCKEditor,
    };
  },
  methods: {
    save() {
      this.validate.$touch();
      if (this.validate.$invalid) return;
      this.$model
        .dispatch("about/save", { updateRows: [this.form.formData] })
        .then(
          () => {
            CloudFun.send("info", {
              subject: `儲存結果`,
              content: `${this.form.formData.Title}更新成功！`,
            });
            this.loadData(this.form.formData.Code);
          },
          (failure) => {
            CloudFun.send("error", { subject: "更新失敗", content: failure });
          }
        );
    },
  },
});
