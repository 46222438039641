
/* eslint-disable */

import { onMounted } from "vue";
import CloudFun, {
  Model,
  defineComponent,
  ref,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
} from "@cloudfun/core";
import {
  VxeColumnPropTypes,
  VxeGridInstance,
  VxeGridProps,
  VxeFormPropTypes,
  VxeModalInstance,
} from "vxe-table";
import FileUpload from "vue-upload-component";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import formatDate from "xe-utils/toDateString";
import VXETablePluginExportXLSX from "vxe-table-plugin-export-xlsx";
import { isNumber } from "xe-utils";

export default defineComponent({
  components: {
    FileUpload,
  },
  setup() {
    const uploader = ref({});
    const modal = ref({} as VxeModalInstance);
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const loading = ref(false);
    const editingRow = ref(null);
    const keyword = ref("");
    const oriUri = ref("");
    const selectdType = ref(-1);
    const jobTypes = ref([] as any);
    const customFilters = ref<Condition[]>([]);
    const isRowSelected = computed(
      () =>
        (grid.value.getRadioRecord && grid.value.getRadioRecord()) ||
        (grid.value.getCheckboxRecords &&
          grid.value.getCheckboxRecords().length > 0)
    );
    const isEditing = computed(
      () =>
        editingRow.value != null && grid.value.isActiveByRow(editingRow.value)
    );

    onMounted(() => {
      jobTypes.value = model
        ? Object.values(model.enums.JobType).map((e) => {
            return { label: e.Name, value: e.Value };
          })
        : undefined;
    });

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: true,
      loading: false,
      customConfig: { storage: true },
      filterConfig: { remote: true },
      sortConfig: { remote: true, multiple: true },
      //editConfig: { trigger: 'manual', mode: 'row', showIcon: false, autoClear: false },
      pagerConfig: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [5, 10, 20],
        layouts: [
          "PrevJump",
          "PrevPage",
          "Jump",
          "PageCount",
          "NextPage",
          "NextJump",
          "Sizes",
          "Total",
        ],
      },
      columns: [
        { type: "checkbox", width: 35, fixed: "left", resizable: false },
        // { field: 'Category.Name', title: '分類名稱' },
        {
          field: "Type",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) =>
            model
              ? Object.values(model.enums.JobType).find(
                  (e) => e.Value === cellValue
                )?.Name
              : undefined,

          // filters: [{ checked: false, label: "包含" }],
          // slots: { filter: "name_filter" },
        },
        {
          field: "Title",
          title: "職缺名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          // filters: [{ checked: false, label: "包含" }],
          // slots: { filter: "name_filter" },
        },
        {
          field: "CompanyName",
          title: "公司名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          // filters: [{ checked: false, label: "包含" }],
          // slots: { filter: "name_filter" },
        },
        {
          field: "Quantity",
          title: "人數",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        // {
        //   field: "PublishDate",
        //   title: "發佈日期",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   formatter: ({ cellValue }) => formatDate(cellValue, "yyyy/MM/dd"),
        // },
        {
          field: "StartDate",
          title: "發佈開始日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => formatDate(cellValue, "yyyy/MM/dd"),
        },
        {
          field: "EndDate",
          title: "發佈結束日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => formatDate(cellValue, "yyyy/MM/dd"),
        },
        {
          field: "Published",
          title: "發布",
          width: 80,
          sortable: true,
          formatter: ({ cellValue }) => {
            return cellValue ? "是" : "否";
          },
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        {
          title: "操作",
          width: 100,
          fixed: "right",
          align: "center",
          resizable: false,
          slots: { default: "operate" },
        },
      ],
      proxyConfig: {
        message: false,
        sort: true,
        filter: true,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params) => {
            const condition = new Condition();

            if (selectdType.value > -1) {
              condition.and("Type", Operator.Equal, selectdType.value);
            }

            if (keyword.value && keyword.value != "") {
              // condition.or("Title", Operator.Contains, keyword.value);
              // condition.or("CompanyName", Operator.Like, keyword.value);
              condition.and(
                new Condition("Title", Operator.Contains, keyword.value).or(
                  new Condition(
                    "CompanyName",
                    Operator.Contains,
                    keyword.value,
                    LogicalConnective.Or
                  )
                )
              );
            }

            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: keyword.value,
              sortings: params.sorts
                .filter((e) => e.property)
                .map(
                  (e) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: condition, // custom filters
            };
            if (queryParams.sortings?.length === 0) {
              queryParams.sortings?.push(new Sorting("Ordinal", 0));
              queryParams.sortings?.push(new Sorting("CreatedTime", 1));
            }
            //console.log(queryParams)
            // filters
            const filters = params.filters
              ? params.filters.filter((e) => e.values.length)
              : null;
            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }
            return new Promise((resolve, reject) =>
              model?.dispatch("job/load", queryParams).then(
                (payload) => {
                  console.log("payload", payload);
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "讀取失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          delete: (params) => {
            const deleteParams = { deleteRows: params.body.removeRecords };
            return new Promise((resolve, reject) =>
              model?.dispatch("job/save", deleteParams).then(
                (payload) => resolve(payload),
                (reason) => {
                  CloudFun.send("error", {
                    subject: "刪除失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
          save: (params) => {
            const saveParams = {
              insertRows: params.body.insertRecords,
              updateRows: params.body.updateRecords,
              deleteRows: params.body.removeRecords,
            };
            return new Promise((resolve, reject) =>
              model?.dispatch("job/save", saveParams).then(
                (payload) => {
                  editingRow.value = null;
                  resolve(payload);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "保存失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
        },
      },
    } as VxeGridProps);

    //modal 執行 新增or修改
    const submitSave = () => {
      var method = form.model == "insert" ? "insert" : "update";
      gridOptions.loading = true;
      //console.log(form.model, method)
      model
        ?.dispatch(`job/${method}`, form.formData)
        .then(
          (paylod) => {
            grid.value.commitProxy("query").finally(() => {});
            CloudFun.send("info", {
              subject: method == "insert" ? "新增成功" : "更新成功",
              content:
                method == "insert" ? "工作職缺新增完成" : "工作職缺更新完成",
            });
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally(() => {
          form.showEdit = false;
          gridOptions.loading = false;
        });
    };

    const form = reactive({
      model: "insert",
      selectRow: true,
      showEdit: false,
      formData: {
        Id: 0,
        Published: false,
        Ordinal: 0,
        Type: 0,
        Introduction: "",
        CompanyName: "",
        Top: false,
        Title: "",
        PublishDate: "",
        StartDate: "",
        EndDate: "",
        Location: "",
        Content: "",
        Education: "",
        Salary: "",
        HasPractice: false,
        Quantity: 1,
        Url: "",
      },
      formRules: {
        Title: [{ required: true, message: "請輸入職務名稱" }],
        CompanyName: [{ required: true, message: "請輸入公司名稱" }],
        PublishDate: [{ required: true, message: "請輸入發佈日期" }],
        Content: [{ required: true, message: "請輸入工作內容" }],
      } as VxeFormPropTypes.Rules,
      formItems: [
        {
          field: "CompanyName",
          title: "公司名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "標題" } },
        },
        {
          field: "Type",
          title: "類型",
          span: 12,
          itemRender: {
            name: "$select",
            options: model
              ? Object.values(model.enums.JobType).map((e) => {
                  return { label: e.Name, value: e.Value };
                })
              : [],
          },
        },
        {
          field: "Title",
          title: "職務名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "職務名稱", clearable: true },
          },
        },
        {
          field: "Quantity",
          title: "需求人數",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", min: "1", placeholder: "需求人數" },
          },
        },
        {
          field: "Education",
          title: "最低學歷",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "最低學歷" } },
        },
        {
          field: "Location",
          title: "工作地點",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "工作地點" } },
        },
        {
          field: "Salary",
          title: "月薪",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "月薪" } },
        },
        {
          field: "HasPractice",
          title: "提供實習機會",
          span: 12,
          itemRender: {
            name: "$radio",
            options: [
              { label: "否", value: false },
              { label: "是", value: true },
            ],
          },
        },
        {
          field: "Top",
          title: "熱門",
          span: 12,
          itemRender: {
            name: "$radio",
            options: [
              { label: "否", value: false },
              { label: "是", value: true },
            ],
          },
        },
        {
          field: "Content",
          title: "工作內容",
          span: 24,
          itemRender: {
            name: "$textarea",
            props: { placeholder: "工作內容", rows: 3, cols: 50 },
          },
        },
        {
          field: "Url",
          title: "應徵方式",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "應徵方式連結" },
          },
        },
        {
          field: "Published",
          title: "是否發佈",
          span: 12,
          itemRender: {
            name: "$radio",
            options: [
              { label: "否", value: false },
              { label: "是", value: true },
            ],
          },
        },

        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "排序" },
          },
        },
        // {
        //   field: "PublishDate",
        //   title: "發佈日期",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: { type: "date", placeholder: "發佈日期" },
        //   },
        // },
        {
          field: "StartDate",
          title: "發佈開始日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "date", placeholder: "發佈開始日期" },
          },
        },
        {
          field: "EndDate",
          title: "發佈結束日期",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "date", placeholder: "發佈結束日期" },
          },
        },
        {
          align: "right",
          span: 24,
          titleAlign: "left",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確認", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        }, //, events: { click: save }
      ] as VxeFormPropTypes.Items,
    });

    const baseUrl = `${process.env.VUE_APP_BACKEND_URL}`;

    onMounted(() => {});

    const onResize = () => {
      //if(form.formData.Picture && form.formData.Picture.Uri) {
      // setTimeout(() => {
      //   console.log('test')
      //   var ins = (document.getElementsByClassName('vxe-modal--box')[0])
      //   var top = (window.screen.availHeight - ins.clientHeight) / 2;
      //   console.log(window.screen.availHeight, ins.clientHeight)
      //   console.log(top)
      //   modal.value.setPosition(top)
      // }, 200);
      //}
    };

    const classicEditor = ClassicEditor;
    const simpleEditorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          // 'htmlEmbed',
          "|",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    return {
      classicEditor,
      simpleEditorConfig,
      form,
      grid,
      gridOptions,
      loading,
      isEditing,
      editingRow,
      isRowSelected,
      keyword,
      customFilters,
      submitSave,
      modal,
      uploader,
      onResize,
      oriUri,
      selectdType,
      jobTypes,
    };
  },
  methods: {
    reset() {
      this.form.formData.Id = 0;
      this.form.formData.Title = "";
      this.form.formData.Published = false;
      this.form.formData.CompanyName = "";
      this.form.formData.Top = false;
      this.form.formData.Ordinal = 0;
      this.form.formData.Type = 0;
      this.form.formData.StartDate = "";
      this.form.formData.EndDate = "";
      this.form.formData.Content = "";
      this.form.formData.Location = "";
      this.form.formData.Education = "";
      this.form.formData.Salary = "";
      this.form.formData.Url = "";
      this.form.formData.HasPractice = false;
      this.form.formData.Quantity = 1;
    },
    edit(row: any) {
      this.$model.dispatch("job/find", row.Id).then(
        (paylod) => {
          Object.assign(this.form.formData, paylod);
          this.form.model = "edit";
          this.form.showEdit = true;
          var item = this.form.formItems[10];
          if (item) item.visible = true;
          //console.log(paylod)
        },
        (failure) =>
          this.$send("error", {
            subject: "操作失敗！",
            content: failure.message,
          })
      );
    },
    addNewRow() {
      this.reset();
      this.form.model = "insert";
      this.form.showEdit = true;
      // var item = this.form.formItems[10];
      // if (item) item.visible = false;
    },
    save(row?: any) {
      const {
        insertRecords,
        updateRecords,
        removeRecords,
      } = this.grid.getRecordset();
      debugger;
      let needSave =
        insertRecords.length || updateRecords.length || removeRecords.length;
      needSave &&=
        row &&
        (insertRecords.some((e) => e._XID === row._XID) ||
          updateRecords.some((e) => e._XID === row._XID) ||
          removeRecords.some((e) => e._XID === row._XID));
      const isRowEditing = row && this.editingRow === row;
      if (needSave) {
        this.grid.commitProxy("save").then(() => {
          if (isRowEditing && this.editingRow === row) this.edit(row);
          else this.editingRow = null;
        });
      } else if (!row || row === this.editingRow) this.editingRow = null;
    },
    remove(row: any) {
      if (row && confirm("確定要刪除此筆資料嗎?")) {
        this.gridOptions.loading = true;
        this.$model.dispatch("job/delete", row.Id).then(
          () => {
            this.grid.commitProxy("query").finally(() => {
              this.gridOptions.loading = false;
            });
          },
          (failure) =>
            this.$send("error", {
              subject: "工作職缺刪除失敗",
              content: failure.message,
            })
        );
      }
    },
    removeSelectedRows() {
      cash("#batch-dropdown").dropdown("hide");
      const rows = this.grid.getCheckboxRecords();
      if (rows && rows.length && confirm("確定要刪除已被選擇的資料嗎?")) {
        this.grid.commitProxy("delete");
      }
    },
    refresh() {
      this.gridOptions.loading = true;
      this.grid.commitProxy("query").finally(() => {
        this.gridOptions.loading = false;
      });
    },
    changeFilterEvent(
      event: Event,
      option: VxeColumnPropTypes.Filter,
      panel: any,
      toogleChecked?: boolean
    ) {
      if (toogleChecked) option.checked = !option.checked;
      if (option.checked && option.data) {
        if (!option.value)
          option.value = new Condition("Name", Operator.Contains);
        option.value.value = option.data;
      } else option.value = option.data = undefined;
      if (toogleChecked || option.checked)
        panel.changeOption(event, !!option.data, option);
    },
    async onExport(
      type: "csv" | "html",
      mode: "current" | "selected" | "all" = "all"
    ) {
      const config: any = { ...this.gridOptions.exportConfig, type, mode };
      if (mode === "all") config.data = await this.$model.dispatch("job/query");
      console.log(config);
      this.grid.exportData({ ...config, type, mode });
    },
    async onPrint(mode: "current" | "selected" | "all" = "all") {
      const config: any = { ...this.gridOptions.printConfig, mode };
      if (mode === "all") config.data = await this.$model.dispatch("job/query");
      this.grid.print(config);
    },
    inputFile(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {});
      }
      if (!newFile && oldFile) {
      }
    },
    inputFilter(newFile: any, oldFile: any, prevent: any) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          //this.alert('Your choice is not a picture')
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
  },
});
