import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ErrorPage from "./views/error-page/Main.vue";
import Classification from "./views/classification/Main.vue";
import Certification from "./views/certification/Main.vue";
import AboutUs from "./views/contents/about-us/Main.vue";
import AboutDetail from "./views/contents/about-us/Detail.vue";
import AboutContent from "./views/contents/about-us/Content.vue";
import Job from "./views/job/Main.vue";
import IndustryCategory from "./views/industryCategory/Main.vue";
import IndustryInfo from "./views/industryInfo/Main.vue";
import FileCategory from "./views/fileCategory/Main.vue";
import Files from "./views/files/Main.vue";
import Industry from "./views/industry/Main.vue";
import ExchangeTopic from "./views/exchangeTopic/Main.vue";
import ExchangeReply from "./views/exchangeReply/Main.vue";
import ExchangeCategory from "./views/exchangeCategory/Main.vue";
import QuestionTopic from "./views/questionTopic/Main.vue";
import QuestionReply from "./views/questionReply/Main.vue";
import QuestionCategory from "./views/questionCategory/Main.vue";
import LinkCategory from "./views/link-category/Main.vue";
import Link from "./views/link/Main.vue";
import Member from "./views/member/Main.vue";
import News from "./views/news/Main.vue";
import NewsCategory from "./views/news-category/Main.vue";
import NewsDetail from "./views/contents/news/Detail.vue";
import BusinessTopic from "./views/businessTopic/Main.vue";
import BusinessReply from "./views/businessReply/Main.vue";
import BusinessCategory from "./views/businessCategory/Main.vue";
const viewRotes: RouteRecordRaw[] = [
  // project views
  { path: "dashboard", component: () => import("./views/dashboard/Main.vue") },
  {
    path: "configuration",
    component: () => import("./views/configuration/Main.vue")
  },
  {
    path: "action-log",
    component: () => import("./views/action-log/Main.vue")
  },
  { path: "role", component: () => import("./views/role/Main.vue") },
  { path: "user", component: () => import("./views/user/Main.vue") },
  {
    path: "user-wizard",
    component: () => import("./views/user-wizard/Main.vue")
  },
  { path: "category", component: () => import("./views/category/Main.vue") },
  { path: "banner", component: () => import("./views/banner/Main.vue") },
  { path: "album", component: () => import("./views/album/Main.vue") },
  { path: "attribute", component: () => import("./views/attribute/Main.vue") },
  {
    path: "news-category",
    component: NewsCategory,
    meta: { rule: "NewsCategory" }
  },
  {
    path: "news",
    component: News
    // meta: { rule: "News" }
  },
  {
    path: "news/detail",
    component: NewsDetail,
    meta: { rule: "News" }
  },
  {
    path: "link-category",
    component: LinkCategory,
    meta: { rule: "RelationLinkCategory" }
  },
  {
    path: "link",
    component: Link,
    meta: { rule: "RelationLink" }
  },
  {
    path: "member",
    component: Member,
    meta: { rule: "Member" }
  },
  {
    path: "industry",
    component: Industry,
    meta: { rule: "About" }
  },
  {
    path: "exchangeTopic",
    component: ExchangeTopic,
    meta: { rule: "Forum" }
  },
  {
    path: "exchangeReply",
    component: ExchangeReply,
    meta: { rule: "Forum" }
  },
  {
    path: "exchangeCategory",
    component: ExchangeCategory,
    meta: { rule: "Forum" }
  },
  {
    path: "questionTopic",
    component: QuestionTopic,
    meta: { rule: "Forum" }
  },
  {
    path: "questionReply",
    component: QuestionReply,
    meta: { rule: "Forum" }
  },
  {
    path: "questionCategory",
    component: QuestionCategory,
    meta: { rule: "Forum" }
  },
  // {
  //   path: "businessTopic",
  //   component: BusinessTopic
  //   // meta: { rule: "Forum" },
  // },
  // {
  //   path: "businessReply",
  //   component: BusinessReply,
  //   meta: { rule: "Forum" }
  // },
  // {
  //   path: "businessCategory",
  //   component: BusinessCategory,
  //   meta: { rule: "Forum" }
  // },
  {
    path: "industryCategory",
    component: IndustryCategory,
    meta: { rule: "IndustryCategory" }
  },
  {
    path: "industryInfo",
    component: IndustryInfo
    // meta: { rule: "Industry" },
  },
  {
    path: "fileCategory",
    component: FileCategory
    // meta: { rule: "FileCategory" },
  },
  {
    path: "downloadManagement",
    component: Files
    // meta: { rule: "Files" },
  },
  // {
  //   path: "book",
  //   component: Book,
  //   meta: { rule: "Book" }
  // },
  // {
  //   path: "order",
  //   component: Order,
  //   meta: { rule: "Order" }
  // },
  {
    path: "classification",
    component: Classification
    // meta: { rule: "Classification" },
  },
  {
    path: "certification",
    component: Certification
    // meta: { rule: "Certification" },
  },
  {
    path: "/about",
    component: AboutUs,
    meta: { rule: "About" }
    // children: [

    // ],
  },
  {
    path: "/about/detail",
    component: AboutDetail,
    meta: { rule: "About" }
  },

  {
    path: "/about/content/:title",
    component: AboutContent,
    meta: { rule: "About" }
  },
  {
    path: "job",
    component: Job
    // meta: { rule: "Job" },
  },
  {
    path: "businessTopic",
    component: BusinessTopic
    // meta: { rule: "Forum" },
  },
  {
    path: "businessReply",
    component: BusinessReply,
    meta: { rule: "Forum" }
  },
  {
    path: "businessCategory",
    component: BusinessCategory,
    meta: { rule: "Forum" }
  }
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/side-menu/Main.vue")
  },
  {
    path: "/simple-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/simple-menu/Main.vue")
  },
  {
    path: "/top-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/top-menu/Main.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/login/Main.vue")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/register/Main.vue")
  },
  { path: "/error-page", name: "error-page", component: ErrorPage },
  { path: "/:pathMatch(.*)*", component: ErrorPage }
];

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});
