<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ form.formData.Title }}</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!-- <button
          class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
          @click="router.back()"
        >
          <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
          取消
        </button> -->
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="save"
        >
          <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
          儲存
        </button>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-3">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="intro-y py-2">
          <div class="box px-5">
            <div
              class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start border-b border-gray-200 dark:border-dark-5"
            >
              <a
                data-toggle="tab"
                data-target="#chinese"
                href="javascript:;"
                class="py-2 sm:mr-8 active"
                >中文版</a
              >
              <a
                data-toggle="tab"
                data-target="#english"
                href="javascript:;"
                class="py-2 sm:mr-8"
                >英文版</a
              >
            </div>
          </div>
        </div>
        <div class="tab-content box">
          <div id="chinese" class="tab-content__pane active">
            <div class="grid grid-cols-12 gap-6 py-5">
              <div class="col-span-12 px-5">
                <label>標題</label>
                <vxe-input
                  placeholder="名稱"
                  class="w-full"
                  v-model.trim="validate.Title.$model"
                  :class="{ 'bg-red-200': validate.Title.$error }"
                ></vxe-input>
                <template v-if="validate.Title.$error">
                  <div
                    v-for="(error, index) in validate.Title.$errors"
                    :key="index"
                    class="text-theme-6 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
            </div>
            <div class="col-span-12 w-full">
              <div class="grid grid-flow-row gap-2">
                <div class="wysiwyg-box">
                  <div class="container-fluid container-xl">
                    <div class="row">
                      <div class="col">
                        <CKEditor
                          id="zhCKEditor"
                          ref="zhCKEditor"
                          v-model="form.formData.Content"
                          :editor="classicEditor"
                          :config="editorConfig"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="english" class="tab-content__pane">
            <div class="grid grid-cols-12 gap-6 py-5">
              <div class="col-span-12 px-5">
                <label>英文標題</label>
                <vxe-input
                  placeholder="名稱"
                  class="w-full"
                  v-model.trim="validate.EnglishTitle.$model"
                  :class="{ 'bg-red-200': validate.EnglishTitle.$error }"
                ></vxe-input>
                <template v-if="validate.EnglishTitle.$error">
                  <div
                    v-for="(error, index) in validate.EnglishTitle.$errors"
                    :key="index"
                    class="text-theme-6 mt-2"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
            </div>
            <div class="col-span-6 justify-self-center">
              <div class="grid grid-flow-row gap-2">
                <div class="wysiwyg-box">
                  <div class="container-fluid container-xl">
                    <div class="row">
                      <div class="col">
                        <CKEditor
                          id="enCKEditor"
                          ref="enCKEditor"
                          v-model="form.formData.EnglishContent"
                          :editor="classicEditor"
                          :config="editorConfig"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="intro-y box p-5 mt-5">
          <div class="grid grid-cols-6 gap-4">
            <div class="col-span-3">
              <label>名稱</label>
              <vxe-input
                placeholder="名稱"
                class="w-full"
                v-model.trim="validate.Title.$model"
                :class="{ 'bg-red-200': validate.Title.$error }"
              ></vxe-input>
              <template v-if="validate.Title.$error">
                <div
                  v-for="(error, index) in validate.Title.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="col-span-2">
              <div class="flex flex-col">
                <label>排序值</label>
                <vxe-input
                  v-model="formData.Ordinal"
                  type="integer"
                  min="0"
                ></vxe-input>
              </div>
            </div>
            <div>
              <div class="flex flex-col">
                <label>發佈狀態</label>
                <vxe-switch
                  v-model="formData.Published"
                  open-label="上架"
                  close-label="下架"
                ></vxe-switch>
              </div>
            </div>
          </div>
          <div class="grid grid-flow-row gap-1 pt-5"></div>
        </div> -->
        <!-- <div class="intro-y box p-5 mt-5">
          <div class="flex items-center border-b border-gray-200">
            <h2 class="font-medium text-base mr-auto">本文</h2>
          </div>

          <div class="grid grid-flow-row gap-2">
            <div class="wysiwyg-box">
              <div class="container-fluid container-xl">
                <div class="row">
                  <div class="col">
                    <CKEditor
                      v-model="formData.Content"
                      :editor="classicEditor"
                      :config="editorConfig"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div class="intro-y col-span-12 lg:col-span-3">
        <div class="intro-y box mt-5 p-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">狀態</h2>
          </div>

         
        </div>
      </div> -->
    </div>

    <div class="w-full sm:w-auto flex pt-4 sm:mt-0">
      <!-- <button
        class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
        @click="router.back()"
      >
        <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
        取消
      </button> -->
      <button
        class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
        @click="save"
      >
        <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
        儲存
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import "@/assets/css/wysiwyg.css";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import CloudFun, {
  defineComponent,
  ref,
  onMounted,
  Condition,
  Operator,
} from "@cloudfun/core";
import { reactive, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { toRefs } from "vue";

export default defineComponent({
  components: {},
  setup() {
    const model = CloudFun.current?.model;
    const route = useRoute();
    const router = useRouter();
    const title = ref("");
    const code = ref(route.query.code as string);
    const lang = ref(route.query.lang as string);
    const zhCKEditor = ref({});
    const enCKEditor = ref({});

    const form = reactive({
      formData: {
        Title: "",
        EnglishTitle: "",
        Content: "",
        EnglishContent: "",
        Code: "",
      },
    });

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList",
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList",
        },
        view: {
          key: "class",
        },
      });
    }

    const formRules = {
      Title: {
        required,
      },
      EnglishTitle: {
        required,
      },
    };

    const validate = useVuelidate(formRules, toRefs(form.formData));
    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin, schemaCustomization],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    const loadData = (value: string) => {
      const params = {
        condition: new Condition("Code", Operator.Equal, value),
      };

      form.formData.Content = "";
      form.formData.EnglishContent = "";
      // eslint-disable-next-line no-unused-expressions
      model?.dispatch("about/query", params).then(
        (payload) => {
          console.log("payload", payload);
          if (payload) {
            const formData = payload[0];
            console.log(
              "🚀 ~ file: Detail.vue ~ line 382 ~ loadData ~ formData",
              formData
            );

            // form.formData.Title = formData.Title;
            // form.formData.EnglishTitle = formData.EnglishTitle;

            // form.formData.Content =
            //   formData.Content && formData.Content.length > 0
            //     ? formData.Content
            //     : "";
            // form.formData.EnglishContent =
            //   formData.EnglishContent && formData.EnglishContent.length > 0
            //     ? formData.EnglishContent
            //     : "";
            Object.assign(form.formData, formData);
          }
        },
        (failure) => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );
    };

    onMounted(() => {
      loadData(route.query.code as string);
    });

    watch(
      () => route.query.code,
      (current) => {
        if (current) loadData(current as string);
      }
    );

    return {
      classicEditor,
      router,
      editorConfig,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      form,
      title,
      loadData,
      validate,
      code,
      lang,
      zhCKEditor,
      enCKEditor,
    };
  },
  methods: {
    save() {
      this.validate.$touch();
      if (this.validate.$invalid) return;
      this.$model
        .dispatch("about/save", { updateRows: [this.form.formData] })
        .then(
          () => {
            CloudFun.send("info", {
              subject: `儲存結果`,
              content: `${this.form.formData.Title}更新成功！`,
            });
            this.loadData(this.form.formData.Code);
          },
          (failure) => {
            CloudFun.send("error", { subject: "更新失敗", content: failure });
          }
        );
    },
  },
});
</script>

<style></style>
