import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "",
  title: "Home",
  subNodes: [
    {
      icon: "HomeIcon",
      to: "dashboard",
      title: "儀錶板",
    },
    // {
    //   icon: 'fa-columns',
    //   title: '選單安排',
    //   subNodes: [
    //     {
    //       icon: '',
    //       to: '/dashboard',
    //       title: '側邊選單',
    //       ignore: true
    //     },
    //     {
    //       icon: '',
    //       to: '/simple-menu/dashboard',
    //       title: '簡易選單',
    //       ignore: true
    //     },
    //     {
    //       icon: '',
    //       to: '/top-menu/dashboard',
    //       title: '上方選單',
    //       ignore: true
    //     }
    //   ]
    // },
    {
      icon: "SettingsIcon",
      to: "configuration",
      title: "系統配置",
    },
    {
      icon: "UserIcon",
      to: "user",
      title: "用戶管理",
      subNodes: [
        {
          icon: "fa-magic",
          to: "user-wizard",
          title: "用戶註冊精靈",
        },
        {
          icon: "UsersIcon",
          to: "role",
          title: "角色管理",
        },
        {
          icon: "UserIcon",
          to: "user",
          title: "用戶管理",
        },
      ],
    },
    {
      icon: "ActivityIcon",
      to: "action-log",
      title: "操作紀錄",
    },
    // {
    //   icon: "fa-sitemap",
    //   to: "category",
    //   title: "類別管理"
    // },
    {
      icon: "FlagIcon",
      to: "banner",
      title: "橫幅圖片管理",
    },
    {
      icon: "UserCheckIcon",
      to: "member",
      title: "會員管理",
      subNodes: [
        // {
        //   icon: "UsersIcon",
        //   to: "classification",
        //   title: "產業分類管理"
        // },
        // {
        //   icon: "UsersIcon",
        //   to: "certification",
        //   title: "國際認證管理",
        // },
        {
          icon: "UserCheckIcon",
          to: "member",
          title: "會員管理",
        },
      ],
    },
    {
      icon: "ListIcon",
      to: "industryInfo",
      title: "產業資訊",
      subNodes: [
        // {
        //   icon: "AlignLeftIcon",
        //   to: 'industryCategory',
        //   title: "分類管理",
        // },
        {
          icon: "AlignLeftIcon",
          to: "industryInfo",
          title: "內容管理",
        },
        // {
        //   icon: "AlignLeftIcon",
        //   to: "book",
        //   title: "出版刊物",
        // },
        // {
        //   icon: "AlignLeftIcon",
        //   to: "order",
        //   title: "訂單管理",
        // },
      ],
    },
    {
      icon: "ListIcon",
      to: "businessTopic",
      title: "討論區管理",
      subNodes: [
        // {
        //   icon: "ListIcon",
        //   to: "businessTopic",
        //   title: "商情諮詢",
        //   subNodes: [
        //     {
        //       icon: "AlignLeftIcon",
        //       to: "businessCategory",
        //       title: "分類管理"
        //     },
        //     {
        //       icon: "AlignLeftIcon",
        //       to: "businessTopic",
        //       title: "主題管理"
        //     },
        //     {
        //       icon: "AlignLeftIcon",
        //       to: "businessReply",
        //       title: "回覆管理"
        //     }
        //   ]
        // },
        // {
        //   icon: "ListIcon",
        //   to: 'questionTopic',
        //   title: "常見問題",
        //   subNodes: [
        //     {
        //       icon: "AlignLeftIcon",
        //       to: 'questionCategory',
        //       title: "分類管理",
        //     },
        //     {
        //       icon: "AlignLeftIcon",
        //       to: 'questionTopic',
        //       title: "主題管理",
        //     },
        //     {
        //       icon: "AlignLeftIcon",
        //       to: 'questionReply',
        //       title: "回覆管理",
        //     },
        //   ]
        // },
        {
          icon: "ListIcon",
          to: "exchangeTopic",
          title: "技術諮詢",
          subNodes: [
            {
              icon: "AlignLeftIcon",
              to: "exchangeCategory",
              title: "分類管理",
            },
            {
              icon: "AlignLeftIcon",
              to: "exchangeTopic",
              title: "主題管理",
            },
            {
              icon: "AlignLeftIcon",
              to: "exchangeReply",
              title: "回覆管理",
            },
          ],
        },
      ],
    },
    {
      icon: "FileTextIcon",
      to: "about",
      title: "內容管理",
      subNodes: [
        {
          icon: "MicIcon",
          to: "/about/detail?code=C01",
          title: "理事長",
        },
        {
          icon: "TargetIcon",
          to: "/about/detail?code=C02",
          title: "目的及宗旨",
        },
        {
          icon: "GitMergeIcon",
          to: "/about/detail?code=C03",
          title: "組織架構",
        },
        {
          icon: "ListIcon",
          to: "News",
          title: "大事記",
        },
        {
          icon: "MicIcon",
          to: "/about/detail?code=C05",
          title: "會員專屬權利",
        },
        {
          icon: "TargetIcon",
          to: "/about/detail?code=C06",
          title: "會員服務與權益",
        },
        {
          icon: "LockIcon",
          to: "/about/detail?code=Privacy",
          title: "隱私權政策",
        },
      ],
    },
    {
      icon: "ListIcon",
      to: "fileCategory",
      title: "下載專區",
      subNodes: [
        {
          icon: "AlignLeftIcon",
          to: "fileCategory",
          title: "分類管理",
        },
        {
          icon: "AlignLeftIcon",
          to: "downloadManagement",
          title: "檔案管理",
        },
      ],
    },

    // {
    //   icon: "MailIcon",
    //   to: "news",
    //   title: "產業資訊管理",
    //   subNodes: [
    //     // {
    //     //   icon: "GitMergeIcon",
    //     //   to: 'news-category',
    //     //   title: "分類管理",
    //     // },
    //     {
    //       icon: "FileTextIcon",
    //       to: "news",
    //       title: "產業新聞",
    //     },
    //     {
    //       icon: "FileTextIcon",
    //       to: "news?category=2",
    //       title: "技術專欄",
    //     },
    //     {
    //       icon: "FileTextIcon",
    //       to: "news?category=3",
    //       title: "活動快報",
    //     },
    //     {
    //       icon: "FileTextIcon",
    //       to: "news?category=4",
    //       title: "政策法規",
    //     },
    //   ],
    // },
    {
      icon: "AnchorIcon",
      to: "link",
      title: "相關連結",
      subNodes: [
        {
          icon: "GitMergeIcon",
          to: "link-category",
          title: "分類管理",
        },
        {
          icon: "FileTextIcon",
          to: "link",
          title: "內容資訊",
        },
      ],
    },
    {
      icon: "HeadphonesIcon",
      to: "job",
      title: "職缺管理",
    },
    // {
    //   icon: "ImageIcon",
    //   to: "album",
    //   title: "相簿管理"
    // },
    // {
    //   icon: "fa-list",
    //   to: "attribute",
    //   title: "屬性管理"
    // },
    // "devider"
    // {
    //   icon: "fa-tachometer-alt",
    //   to: "midone-dashboard",
    //   title: "Dashboard"
    // },
    // {
    //   icon: "InboxIcon",
    //   to: "midone-inbox",
    //   title: "Inbox"
    // },
    // {
    //   icon: "HardDriveIcon",
    //   to: "midone-file-manager",
    //   title: "File Manager"
    // },
    // {
    //   icon: "CreditCardIcon",
    //   to: "midone-point-of-sale",
    //   title: "Point of Sale"
    // },
    // {
    //   icon: "MessageSquareIcon",
    //   to: "midone-chat",
    //   title: "Chat"
    // },
    // {
    //   icon: "FileTextIcon",
    //   to: "midone-post",
    //   title: "Post"
    // },
    // "devider",
    // {
    //   icon: "EditIcon",
    //   to: "midone-crud-data-list",
    //   title: "Crud",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-crud-data-list",
    //       title: "Data List"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-crud-form",
    //       title: "Form"
    //     }
    //   ]
    // },
    // {
    //   icon: "UsersIcon",
    //   to: "midone-users-layout-1",
    //   title: "Users",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-users-layout-1",
    //       title: "Layout 1"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-users-layout-2",
    //       title: "Layout 2"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-users-layout-3",
    //       title: "Layout 3"
    //     }
    //   ]
    // },
    // {
    //   icon: "TrelloIcon",
    //   to: "midone-profile-overview-1",
    //   title: "Profile",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-profile-overview-1",
    //       title: "Overview 1"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-profile-overview-2",
    //       title: "Overview 2"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-profile-overview-3",
    //       title: "Overview 3"
    //     }
    //   ]
    // },
    // {
    //   icon: "LayoutIcon",
    //   to: "midone-wizard-layout-1",
    //   title: "Pages",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-wizard-layout-1",
    //       title: "Wizards",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-wizard-layout-1",
    //           title: "Layout 1"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-wizard-layout-2",
    //           title: "Layout 2"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-wizard-layout-3",
    //           title: "Layout 3"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-blog-layout-1",
    //       title: "Blog",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-blog-layout-1",
    //           title: "Layout 1"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-blog-layout-2",
    //           title: "Layout 2"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-blog-layout-3",
    //           title: "Layout 3"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-pricing-layout-1",
    //       title: "Pricing",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-pricing-layout-1",
    //           title: "Layout 1"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-pricing-layout-2",
    //           title: "Layout 2"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-invoice-layout-1",
    //       title: "Invoice",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-invoice-layout-1",
    //           title: "Layout 1"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-invoice-layout-2",
    //           title: "Layout 2"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-faq-layout-1",
    //       title: "FAQ",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-faq-layout-1",
    //           title: "Layout 1"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-faq-layout-2",
    //           title: "Layout 2"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-faq-layout-3",
    //           title: "Layout 3"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "login",
    //       title: "Login"
    //     },
    //     {
    //       icon: "",
    //       to: "register",
    //       title: "Register"
    //     },
    //     {
    //       icon: "",
    //       to: "error-page",
    //       title: "Error Page"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-update-profile",
    //       title: "Update profile"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-change-password",
    //       title: "Change Password"
    //     }
    //   ]
    // },
    // "devider",
    // {
    //   icon: "InboxIcon",
    //   to: "midone-regular-table",
    //   title: "Components",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-regular-table",
    //       title: "Table",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-regular-table",
    //           title: "Regular Table"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-tabulator",
    //           title: "Tabulator"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-accordion",
    //       title: "Accordion"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-button",
    //       title: "Button"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-modal",
    //       title: "Modal"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-alert",
    //       title: "Alert"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-progress-bar",
    //       title: "Progress Bar"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-tooltip",
    //       title: "Tooltip"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-dropdown",
    //       title: "Dropdown"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-toast",
    //       title: "Toast"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-typography",
    //       title: "Typography"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-icon",
    //       title: "Icon"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-loading-icon",
    //       title: "Loading Icon"
    //     }
    //   ]
    // },
    // {
    //   icon: "SidebarIcon",
    //   to: "midone-regular-form",
    //   title: "Forms",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-regular-form",
    //       title: "Regular Form"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-datepicker",
    //       title: "Datepicker"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-tail-select",
    //       title: "Tail Select"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-file-upload",
    //       title: "File Upload"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-wysiwyg-editor",
    //       title: "Wysiwyg Editor"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-validation",
    //       title: "Validation"
    //     }
    //   ]
    // },
    // {
    //   icon: "HardDriveIcon",
    //   to: "midone-chart",
    //   title: "Widgets",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-chart",
    //       title: "Chart"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-slider",
    //       title: "Slider"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-image-zoom",
    //       title: "Image Zoom"
    //     }
    //   ]
    // }
  ],
};

export default sitemap;
