
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import "@/assets/css/wysiwyg.css";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import CloudFun, { defineComponent, ref, onMounted } from "@cloudfun/core";
import { reactive } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { toRefs } from "vue";
import { News } from "@/models/data";

export default defineComponent({
  components: {},
  setup() {
    const model = CloudFun.current?.model;
    const route = useRoute();
    const router = useRouter();
    const title = ref("");
    const id = ref(route.query.id);
    const formData = reactive({ Title: "" } as News);

    function schemaCustomization(editor: any) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList",
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList",
        },
        view: {
          key: "class",
        },
      });
    }

    const formRules = {
      Title: {
        required,
      },
    };

    const validate = useVuelidate(formRules, toRefs(formData));
    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin, schemaCustomization],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
    };

    const initData = () => {
      formData.Id = 0;
      formData.Title = "";
      formData.Ordinal = 0;
      formData.CategoryId = route.query.category as string;
      formData.Category = {
        Id: parseInt(route.query.category as string),
        Name: route.query.categoryName as string,
      };
      formData.Top = false;
      formData.Published = true;
      formData.Content = "";
      formData.IsNew = false;
    };

    const loadData = (value: string) => {
      // eslint-disable-next-line no-unused-expressions
      model?.dispatch("news/find", value).then(
        (payload) => {
          Object.assign(formData, payload);
        },
        (failure) => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );
    };

    onMounted(() => {
      if (route.query.id) loadData(route.query.id as string);
      else initData();
    });

    return {
      classicEditor,
      router,
      editorConfig,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      formData,
      title,
      loadData,
      validate,
      id,
      initData,
    };
  },
  methods: {
    save() {
      this.validate.$touch();
      console.log(this.validate);
      if (this.validate.$invalid) return;
      const params = { insertRows: Array<News>(), updateRows: Array<News>() };

      if (this.formData.Id == null || this.formData.Id === 0)
        params.insertRows.push(this.formData);
      else params.updateRows.push(this.formData);
      this.$model.dispatch("news/save", params).then(
        (payload) => {
          CloudFun.send("success", {
            subject: `${this.formData.Title} 更新成功！`,
          });
          this.$router.back();
        },
        (failure) => {
          CloudFun.send("error", { subject: "更新失敗", content: failure });
        }
      );
    },
  },
});
